import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Test an API from command line (with curl)`}</h2>
    <Title mdxType="Title">Get an access token</Title>
    <p>{`The simplest way to obtain an access token for testing purposes is to use the implicit OAuth 2.0 flow. This flow allows clients to get access tokens directly from the auth endpoint. However, `}<strong parentName="p">{`for production purposes it is not recommended`}</strong>{` due to the inherent risks of returning access tokens in an HTTP redirect without any confirmation that it has been received by the client.`}</p>
    <pre><code parentName="pre" {...{}}>{`https://authentication.video.ibm.com/authorize
?response_type=token
&client_id=057ce944b015ec9fdf546dfbbe1b7af4b19e8158
&redirect_uri=https://yourdomain.com/redirect
&device_name=MyDevice
&state=XYZ
`}</code></pre>
    <p>{`After logging in, the browser is redirected to a special URL (defined by the client), passing the Access Token in the URL. As you can see in the examples below, you should use Authorization HTTP header with the token in the requests: `}<inlineCode parentName="p">{`Authorization: Bearer 3c2573673b782f6544405a22636f3d5d3b6f3950`}</inlineCode></p>
    <Title mdxType="Title">Create a channel (POST)</Title>
    <pre><code parentName="pre" {...{}}>{`curl \\
    -i \\
    -X POST \\
    -H "Authorization: Bearer 3c2573673b782f6544405a22636f3d5d3b6f3950" \\
    -d "title=Whatever+Test+1234" \\
    https://api.video.ibm.com/users/self/channels.json
`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`HTTP/1.1 201 Created

{"channel":{"id":"13091307","title":"Whatever Test 1234","url":"whatever-test-1234","tiny_url":"http://ustre.am/SVE7"}}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      